<template>
  <div class="system-messages">
    <el-table :data="tableData" style="width: 100%" :header-cell-style="{ background: '#f6f6f6', color: '#35323B', fontWeight: 400 }">
      <el-table-column prop="name" label="内容"></el-table-column>
      <el-table-column prop="time" label="时间" width="200"></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "Index",
  components: {},
  props: {},
  data() {
    return {
      tableData: [
        { name: "【公告】您收到了新作业提交通知。学生李华于2024年4月12日提交了一份作业任务，题目为“美妆达人秀：化妆技巧分享与交流”。您可以在作业管理界面查看并评分。", time: "10分钟前" },
        { name: "【公告】请注意，实训任务中的作业评分截止时间即将到期。您还有2天时间完成对学生提交的作业的评分工作。请尽快登录系统进行评分，以确保学生及时收到反馈。", time: "1小时前" },
        { name: "【公告】您收到了新作业提交通知。学生张三于2024年4月15日提交了一份作业任务，题目为“美妆达人秀：化妆技巧分享与交流”。您可以在作业管理界面查看并评分。", time: "1天前" },
        { name: "【公告】学生李艳已经完成了一项直播任务，需要您的评分。请尽快登录系统进行评分，以便学生能及时收到反馈。您可以在系统中找到任务并对学生的表现进行评价。感谢您的配合和持！", time: "14天前" },
      ],
    };
  },
  computed: {},
  methods: {},
};
</script>

<style scoped lang="scss">
.system-messages {
  box-sizing: border-box;
  height: 100%;
  padding: 20px;
}
</style>
